@font-face {
  font-family: "Avenir Roman";
  src: url("src/assets/font/Avenir-Roman.eot");
  src: local("Avenir Roman"), local("Avenir-Roman"),
    url("src/assets/font/Avenir-Roman.eot?#iefix") format("embedded-opentype"),
    url("src/assets/font/Avenir-Roman.woff2") format("woff2"),
    url("src/assets/font/Avenir-Roman.woff") format("woff"),
    url("src/assets/font/Avenir-Roman.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Avenir Light";
  src: url("src/assets/font/Avenir-Light.eot");
  src: local("Avenir Light"), local("Avenir-Light"),
    url("src/assets/font/Avenir-Light.eot?#iefix") format("embedded-opentype"),
    url("src/assets/font/Avenir-Light.woff2") format("woff2"),
    url("src/assets/font/Avenir-Light.woff") format("woff"),
    url("src/assets/font/Avenir-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Avenir Heavy";
  src: url("src/assets/font/Avenir-Heavy.eot");
  src: local("Avenir Heavy"), local("Avenir-Heavy"),
    url("src/assets/font/Avenir-Heavy.eot?#iefix") format("embedded-opentype"),
    url("src/assets/font/Avenir-Heavy.woff2") format("woff2"),
    url("src/assets/font/Avenir-Heavy.woff") format("woff"),
    url("src/assets/font/Avenir-Heavy.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Avenir Book";
  src: url("src/assets/font/Avenir-Book.eot");
  src: local("Avenir Book"), local("Avenir-Book"),
    url("src/assets/font/Avenir-Book.eot?#iefix") format("embedded-opentype"),
    url("src/assets/font/Avenir-Book.woff2") format("woff2"),
    url("src/assets/font/Avenir-Book.woff") format("woff"),
    url("src/assets/font/Avenir-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Avenir Black";
  src: url("src/assets/font/Avenir-Black.eot");
  src: local("Avenir Black"), local("Avenir-Black"),
    url("src/assets/font/Avenir-Black.eot?#iefix") format("embedded-opentype"),
    url("src/assets/font/Avenir-Black.woff2") format("woff2"),
    url("src/assets/font/Avenir-Black.woff") format("woff"),
    url("src/assets/font/Avenir-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Avenir Medium";
  src: url("src/assets/font/Avenir-Medium.eot");
  src: local("Avenir Medium"), local("Avenir-Medium"),
    url("src/assets/font/Avenir-Medium.eot?#iefix") format("embedded-opentype"),
    url("src/assets/font/Avenir-Medium.woff2") format("woff2"),
    url("src/assets/font/Avenir-Medium.woff") format("woff"),
    url("src/assets/font/Avenir-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

* {
  font-family: "Avenir Roman";
}

.chakra-modal__content-container::-webkit-scrollbar {
  width: 6px;
}

.chakra-modal__content-container::-webkit-scrollbar-track {
  width: 6px;
}

.chakra-modal__content-container::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 20px;
}

/* @font-face {
  font-family: "Avenir Oblique";
  src: url("src/assets/font/Avenir-BookOblique.eot");
  src: local("Avenir Book Oblique"), local("Avenir-BookOblique"),
    url("src/assets/font/Avenir-BookOblique.eot?#iefix")
      format("embedded-opentype"),
    url("src/assets/font/Avenir-BookOblique.woff2") format("woff2"),
    url("src/assets/font/Avenir-BookOblique.woff") format("woff"),
    url("src/assets/font/Avenir-BookOblique.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
} */

/* @font-face {
  font-family: "Avenir Black Oblique";
  src: url("src/assets/font/Avenir-BlackOblique.eot");
  src: local("Avenir Black Oblique"), local("Avenir-BlackOblique"),
    url("src/assets/font/Avenir-BlackOblique.eot?#iefix")
      format("embedded-opentype"),
    url("src/assets/font/Avenir-BlackOblique.woff2") format("woff2"),
    url("src/assets/font/Avenir-BlackOblique.woff") format("woff"),
    url("src/assets/font/Avenir-BlackOblique.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
} */

/* @font-face {
  font-family: "Avenir Heavy Italic";
  src: url("src/assets/font/Avenir-HeavyOblique.eot");
  src: local("Avenir Heavy Oblique"), local("Avenir-HeavyOblique"),
    url("src/assets/font/Avenir-HeavyOblique.eot?#iefix")
      format("embedded-opentype"),
    url("src/assets/font/Avenir-HeavyOblique.woff2") format("woff2"),
    url("src/assets/font/Avenir-HeavyOblique.woff") format("woff"),
    url("src/assets/font/Avenir-HeavyOblique.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
} */

/* @font-face {
  font-family: "Avenir Italic";
  src: url("src/assets/font/Avenir-MediumOblique.eot");
  src: local("Avenir Medium Oblique"), local("Avenir-MediumOblique"),
    url("src/assets/font/Avenir-MediumOblique.eot?#iefix")
      format("embedded-opentype"),
    url("src/assets/font/Avenir-MediumOblique.woff2") format("woff2"),
    url("src/assets/font/Avenir-MediumOblique.woff") format("woff"),
    url("src/assets/font/Avenir-MediumOblique.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
} */
/* 
@font-face {
  font-family: "Avenir Light Italic";
  src: url("src/assets/font/Avenir-LightOblique.eot");
  src: local("Avenir Light Oblique"), local("Avenir-LightOblique"),
    url("src/assets/font/Avenir-LightOblique.eot?#iefix")
      format("embedded-opentype"),
    url("src/assets/font/Avenir-LightOblique.woff2") format("woff2"),
    url("src/assets/font/Avenir-LightOblique.woff") format("woff"),
    url("src/assets/font/Avenir-LightOblique.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
} */

/* @font-face {
  font-family: "Avenir";
  src: url("src/assets/font/Avenir-Oblique.eot");
  src: local("Avenir Oblique"), local("Avenir-Oblique"),
    url("src/assets/font/Avenir-Oblique.eot?#iefix") format("embedded-opentype"),
    url("src/assets/font/Avenir-Oblique.woff2") format("woff2"),
    url("src/assets/font/Avenir-Oblique.woff") format("woff"),
    url("src/assets/font/Avenir-Oblique.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
} */

.react-datepicker__month-container,
.full {
  width: 100% !important;
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__day--today {
  border-radius: 50% !important;
  background-color: #f2f5fc !important;
  color: #171e38 !important;
}

.react-datepicker__day--range-start,
.react-datepicker__day--range-end,
.react-datepicker__day--selected {
  border-radius: 50% !important;
  background-color: #0a47ba !important;
  color: #f2f5fc !important;
}

.react-datepicker__day:hover {
  border-radius: 50% !important;
}
